<template>
  <div :id="id1" style="width:100%;height:260px">
    <div :id="id2" style="width:100%;height:100%"></div>
     <notData v-if="propData.length == 0" />
  </div>
</template>
<script>
export default {
  props: {
    propData: {
      type: Array,
      default: () => []
    },
    id1: {
      type: String,
      default: 'abc'
    },
    id2: {
      type: String,
      default: 'cba'
    },
    // 是镂空还是不镂空
    radius: {
      type: [Array, String],
      default: () => ['40%', '80%']
    }
  },
  data () {
    return {
    }
  },
  watch: {
    propData: {
      handler (n) {
        this.data = n
        this.init()
      },
      deep: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeCharts, true)
    this.$erd(this.id1, this.id2)
    this.data = this.propData
    this.init()
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeCharts, true)
  },
  methods: {
    resizeCharts () {
      const myChart = this.$echarts.init(document.getElementById(this.id2))
      myChart.resize()
    },
    init () {
      this.name = this.data.map(item => item.codeName)
      this.dataX = this.data.map(item => ({ name: item.codeName, value: item.amount }))
      const myChart = this.$echarts.init(
        document.getElementById(this.id2)
      )
      const option = {
        tooltip: {
          confine: true,
          transitionDuration: 0, // 页面抖动问题
          trigger: 'item',
          formatter: '{b}:{c} 占比:{d}%'
        },
        // grid: {
        //   left: '1%',
        //   right: '1%',
        //   top: '1%',
        //   bottom: '1%',
        //   containLabel: true
        // },
        color: [
          '#5470c6',
          '#91cc75',
          '#fac858',
          '#ee6666',
          '#73c0de',
          '#3ba272',
          '#fc8452',
          '#9a60b4',
          '#ea7ccc'
        ],
        legend: {
          type: 'scroll',
          orient: 'vertical',
          icon: 'circle',
          top: '10%',
          left: '48%',
          itemWidth: 8,
          formatter: params => {
            const dataAll = this.dataX.map(item => item.value)
            const total = dataAll.reduce((x, y) => x + y)
            const value = this.dataX.filter(item => item.name === params)[0].value
            if (total === 0) {
              return `${params}:0.00%`
            }
            const name = params
            return `${name}:${((value * 100) / total).toFixed(2)}%;台数:${value}`
          }
        },
        series: [
          {
            name: this.name,
            type: 'pie',
            radius: '70%',
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: this.dataX
          }
        ]
      }
      myChart.setOption(option, true)
    }
  }
}
</script>
