<template>
  <div class="layout_common chargeback_reason">
    <div class="main_box">
      <div class="main_box_con">
        <searchToChargeback
          :isDetailPage="true"
          @search="search"
          @reset="search"
        />
        <ul class="echarts" v-loading="loading">
          <li>
            <div class="title">退机原因</div>
            <pieEcharts
              id1="analy_pie_box1"
              id2="analy_pie_echart1"
              :propData="reasonData"
            />
          </li>
          <li>
            <div class="title">退机商家分布</div>
            <pieEcharts
              id1="analy_pie_box2"
              id2="analy_pie_echart2"
              :propData="storeData"
            />
          </li>
          <li>
            <div class="title">退机机型分布</div>
            <pieEcharts
              id1="analy_pie_box3"
              id2="analy_pie_echart3"
              :propData="categoryData"
            />
          </li>
          <li>
            <div class="title">使用时长分布</div>
            <pieEcharts
              id1="analy_pie_box4"
              id2="analy_pie_echart4"
              :propData="durationData"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import searchToChargeback from '@/views/data-center/components/searchToChargeback'
import pieEcharts from '@/views/data-center/components/pieEcharts'
import { chargebackReason } from '@/api'
// import { formatDate, diffDate } from '@/utils/common'
export default {
  components: {
    searchToChargeback,
    pieEcharts
  },
  data () {
    return {
      loading: false,
      isShow: false,
      storeId: '',
      storeName: '',
      startTime: '',
      endTime: '',
      timeType: 3,
      type: 1,
      level: '',
      cityId: '',
      durationType: '',
      chargebackCategory: '',
      chargebackType: '',
      reason: '',
      reasonData: [],
      storeData: [],
      categoryData: [],
      durationData: []
    }
  },
  mounted () {
    const {
      timeType,
      time,
      level,
      cityId,
      storeId,
      type,
      durationType,
      chargebackCategory,
      chargebackType,
      reason
    } = this.$route.query
    this.timeType = +timeType
    this.storeId = storeId
    this.startTime = time
    this.endTime = time
    this.level = level
    this.cityId = cityId
    this.durationType = durationType
    this.chargebackCategory = chargebackCategory
    this.chargebackType = chargebackType
    this.reason = reason
    this.type = type
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        level: this.level,
        cityId: this.cityId,
        type: this.type,
        timeType: this.timeType,
        storeId: this.storeId,
        durationType: this.durationType,
        chargebackCategory: this.chargebackCategory,
        chargebackType: this.chargebackType,
        reason: this.reason,
        start: this.startTime,
        end: this.endTime
      }
      this.loading = true
      chargebackReason(params).then(res => {
        this.loading = false
        const { reason, store, category, duration } = res.data
        this.reasonData = reason
        this.storeData = store
        this.categoryData = category
        this.durationData = duration
      })
    },
    search (val) {
      this.curPage = 1
      const {
        timeType,
        startTime,
        endTime,
        level,
        cityId,
        storeId,
        type,
        durationType,
        chargebackCategory,
        chargebackType,
        reason
      } = val
      this.timeType = timeType
      this.startTime = startTime
      this.endTime = endTime
      this.type = type
      this.level = level
      this.cityId = cityId
      this.storeId = storeId
      this.durationType = durationType
      this.chargebackCategory = chargebackCategory
      this.chargebackType = chargebackType
      this.reason = reason
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.chargeback_reason {
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 50%;
    height: 280px;
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      line-height: 40px;
      background-color: #eee;
    }
  }
}
</style>
